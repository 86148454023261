<template>
  <div class="searchWrapper">
    <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <div class="col_box">
        <div class="col_left">
          <el-form-item :label="$t('searchModule.Payment_channels')">
            <el-select v-model="formInline.channelType" placeholder="活动区域">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.channelTypeDesc"
                :value="area.channelType"
                :key="area.channelType"
                v-for="area in payList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_method')">
            <el-select v-model="formInline.payType" placeholder="活动区域">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.payTypeName"
                :value="area.payType"
                :key="area.payType"
                v-for="area in payWayList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付成功时间">
            <el-date-picker
              v-model="searchDate"
              type="daterange"
              :clearable="false"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <i class="el-icon-date el-icon-date-custom"></i>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Our_serial_number')">
            <el-input v-model="formInline.orderNo" placeholder="我方流水号"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Third_party_serial_number')">
            <el-input v-model="formInline.tradeNo" placeholder="第三方流水号"></el-input>
          </el-form-item>
        </div>
        <div class="col_right">
          <el-button type="primary" icon="el-icon-search" @click="onSubmit(1)">{{ $t('button.search') }}</el-button>
          <el-button @click="empty" icon="el-icon-delete">{{ $t('button.reset') }}</el-button>
        </div>
      </div>
      <div class="col_box_boder"></div>
      <div class="col_box h44">
        <div class="col_left"></div>
        <div class="col_right mbd4">
          <el-button type="info" icon="el-icon-upload2" @click="exportData">导出 </el-button>
        </div>
      </div>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column align="center" prop="channelTypeDesc" :label="$t('searchModule.Payment_channels')"> </el-table-column>
      <el-table-column align="center" prop="payTypeDesc" :label="$t('searchModule.Payment_method')"> </el-table-column>
      <el-table-column align="center" label="支付金额">
        <div slot-scope="scope">
          <span>{{ scope.row.refundMoney / 100 }}</span>
        </div>
      </el-table-column>
      <el-table-column prop="refundTime" align="center" label="支付成功时间"> </el-table-column>
      <el-table-column align="center" prop="sysTradeNo" :label="$t('searchModule.Our_serial_number')"> </el-table-column>
      <el-table-column align="center" prop="outTradeNo" :label="$t('searchModule.Third_party_serial_number')"> </el-table-column>
      <el-table-column align="center" prop="operatorName" :label="$t('searchModule.Operator')"> </el-table-column>
      <el-table-column align="center" label="操作" width="80">
        <template slot-scope="scope">
          <!-- <el-button @click="handleClick(scope.row)"  size="small">查看</el-button> -->
          <!-- <el-button  size="small">退款</el-button> -->
          <el-dropdown
            style="color: #20a0ff; cursor: pointer"
            @command="handleCommand($event, scope.row)"
          >
            <el-button type="text" size="small" style="padding: 0">
              操作
              <i class="el-icon-arrow-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="check">查看</el-dropdown-item>
              <!-- <el-dropdown-item command="refund">退款</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 20px">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageParams.currentPage"
        :page-size="pageParams.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="pageParams.total"
      >
      </el-pagination>
    </div>
    <el-dialog title="异常支付退款" :visible.sync="dialogVisible" width="70%">
      <div>
        <h5 style="margin-bottom: 20px">支付记录</h5>
        <el-table :data="payData" style="width: 100%">
          <el-table-column prop="parkName" :label="$t('searchModule.park_name')"> </el-table-column>
          <el-table-column prop="plateNumber" :label="$t('searchModule.plate_number')"> </el-table-column>
          <el-table-column prop="entryTime" :label="$t('searchModule.Entry_time')"> </el-table-column>
          <el-table-column prop="exitTime" :label="$t('searchModule.Appearance_time')"> </el-table-column>
          <el-table-column label="应收">
            <div slot-scope="scope">
              <span>{{ scope.row.shouldPay / 100 }}</span>
            </div>
          </el-table-column>
          <el-table-column label="优惠">
            <div slot-scope="scope">
              <span>{{ scope.row.agioPay / 100 }}</span>
            </div>
          </el-table-column>
          <el-table-column label="实收">
            <div slot-scope="scope">
              <span>{{ scope.row.money / 100 }}</span>
            </div>
          </el-table-column>
          <!-- <el-table-column
            prop="name"
            label="支付状态">
          </el-table-column> -->
          <el-table-column prop="tradeNo" :label="$t('searchModule.Third_party_serial_number')"> </el-table-column>
        </el-table>
        <h5 style="margin-top: 30px; margin-bottom: 20px">优惠券</h5>
        <el-table :data="couponData" style="width: 100%">
          <el-table-column prop="couponName" :label="$t('searchModule.Coupon_Name')"> </el-table-column>
          <el-table-column prop="useStatusDesc" label="使用状态"> </el-table-column>
          <el-table-column prop="orderId" label="使用该券的停车支付订单ID"> </el-table-column>
        </el-table>
        <!-- <el-row :gutter="10" style="margin-top: 20px;">
          <el-col :span="3">
            <span>备注：</span>
          </el-col>
          <el-col :span="21">
            <el-input
              class="el_pay_textarea"
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="textarea">
            </el-input>
          </el-col>
        </el-row> -->
        <el-row :gutter="10" style="margin-top: 20px">
          <el-col :span="3">
            <span>备注：</span>
          </el-col>
          <el-col :span="21">
            <el-input
              class="el_pay_textarea"
              type="textarea"
              :rows="3"
              disabled
              placeholder="请输入内容"
              v-model="remark"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
import { exportExcelNew, dateFormat } from "@/common/js/public";
export default {
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime());
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
    return {
      dialogVisible: false,
      tableData: [],
      payData: [],
      couponData: [],
      searchDate: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      // page: 1,
      // pageSize: 15,
      payList: [],
      payWayList: [],
      textarea: "",
      formInline: {
        channelType: "",
        payType: "",
        orderNo: "",
        tradeNo: "",
        startDate: dateFormat(start, "yyyy-MM-dd"),
        endDate: dateFormat(end, "yyyy-MM-dd"),
        dealType: 7,
      },
      remark: "",
      pageParams: {
        currentPage: 1,
        pageSize: 10,
        total: 1,
      },
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.pageParams.currentPage = page;
      this.onSubmit();
    },
    handleCommand(command, row) {
      if (command === "check") {
        this.handleClick(row);
      }
    },
    empty() {
      const start = new Date();
      const end = new Date(new Date().getTime());
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
      this.formInline.channelType = "";
      this.formInline.payType = "";
      this.formInline.orderNo = "";
      this.formInline.tradeNo = "";
      this.formInline.dealType = 7;
      this.formInline.startDate = dateFormat(start, "yyyy-MM-dd");
      this.formInline.endDate = dateFormat(end, "yyyy-MM-dd");
      this.searchDate = [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")];
    },
    onSubmit(page) {
      if (page) {
        this.pageParams.currentPage = page;
      }
      this.formInline.page = this.pageParams.currentPage;
      this.formInline.pageSize = this.pageParams.pageSize;
      this.$axios
        .get("/acb/2.0/repeatPayOrder/refundRecordList", {
          data: this.formInline,
        })
        .then((res) => {
          this.tableData = res.value.list;
          // eslint-disable-next-line no-bitwise
          // this.total = res.value.total * 1 | 0
          this.pageParams.currentPage = res.value.pageNum;
          this.pageParams.pageSize = parseInt(res.value.pageSize);
          this.pageParams.total = parseInt(res.value.total);
        })
        .catch((_) => {
          this.tableData = [];
          this.total = 0;
        });
    },
    // 支付渠道
    getPayType(data) {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          this.payList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
    // 支付方式
    getPayWay(data) {
      this.$axios
        .get("/acb/2.0/payment/payTypeDictList")
        .then((res) => {
          this.payWayList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
    handleClick(row) {
      console.log("row", row);
      // this.dialogVisible = true
      this.$axios
        .get("/acb/2.0/repeatPayOrder/refundDetail", {
          data: {
            flatAccountRecordId: row.flatAccountRecordId,
            type: 2,
          },
        })
        .then((res) => {
          this.couponData = [];
          this.dialogVisible = true;
          // this.tableData = res.value.list
          this.payData = res.value.orderList;
          // this.couponData = res.value.couponVo
          this.remark = res.value.remark;
          this.couponData.push({
            couponName: res.value.couponVo.couponName,
            useStatusDesc: res.value.couponVo.useStatusDesc,
            orderId: res.value.couponVo.orderId,
          });
        })
        .catch((_) => {
          // this.tableData = [];
          this.total = 0;
        });
    },
    exportData() {
      exportExcelNew("/acb/2.0/repeatPayOrder/download", this.formInline);
    },
  },
  mounted() {
    this.getPayType();
    this.getPayWay();
    this.onSubmit();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el_pay_textarea{
  >>> textarea{
    resize:none
  }
}
</style>

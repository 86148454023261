<template>
  <div class="searchWrapper bgFFF">
    <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
    <el-form :inline="true" :model="formInline">
      <div class="col_box">
        <div class="col_left">
          <el-form-item :label="$t('searchModule.Payment_channels')">
            <el-select v-model="formInline.channelType" placeholder="活动区域">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.channelTypeDesc"
                :value="area.channelType"
                :key="area.channelType"
                v-for="area in payList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_method')">
            <el-select v-model="formInline.payType" placeholder="活动区域">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.payTypeName"
                :value="area.payType"
                :key="area.payType"
                v-for="area in payWayList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付成功时间">
            <el-date-picker
              v-model="searchDate"
              type="daterange"
              :clearable="false"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <i class="el-icon-date el-icon-date-custom"></i>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Our_serial_number')">
            <el-input v-model="formInline.orderNo" placeholder="我方流水号"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Third_party_serial_number')">
            <el-input v-model="formInline.tradeNo" placeholder="第三方流水号"></el-input>
          </el-form-item>
        </div>
        <div class="col_right">
          <el-button type="primary" icon="el-icon-search" @click="onSubmit(1)">{{ $t('button.search') }}</el-button>
          <el-button icon="el-icon-delete" @click="clearData">{{ $t('button.reset') }}</el-button>
        </div>
      </div>
      <div class="col_box_boder"></div>
      <div class="col_box h44">
        <div class="col_left"></div>
        <div class="col_right mbd4">
          <el-button type="info" icon="el-icon-upload2" @click="exportData">导出 </el-button>
        </div>
      </div>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column align="center" prop="channelTypeDesc" :label="$t('searchModule.Payment_channels')"> </el-table-column>
      <el-table-column align="center" prop="payTypeDesc" :label="$t('searchModule.Payment_method')"> </el-table-column>
      <el-table-column align="center" label="支付金额">
        <div slot-scope="scope">
          <span>{{ scope.row.money / 100 }}</span>
        </div>
      </el-table-column>
      <el-table-column prop="payTime" align="center" label="支付成功时间"> </el-table-column>
      <el-table-column prop="paymentId" align="center" :label="$t('searchModule.Our_serial_number')"> </el-table-column>
      <el-table-column prop="tradeNo" align="center" :label="$t('searchModule.Third_party_serial_number')"> </el-table-column>
      <!-- <el-table-column prop="nativeTradeNo" align="center" :label="$t('searchModule.Native_channel_serial_number')">
      </el-table-column> -->
      <!-- <el-table-column
        prop="payTypeDesc"
        :label="$t('searchModule.state')">
      </el-table-column> -->
      <el-table-column align="center" label="操作" width="80">
        <template slot-scope="scope">
          <el-dropdown
            style="color: #20a0ff; cursor: pointer"
            @command="handleCommand($event, scope.row)"
          >
            <el-button type="text" size="small" style="padding: 0">
              操作
              <i class="el-icon-arrow-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="check">查看</el-dropdown-item>
              <el-dropdown-item command="refund">退款</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button @click="handleClick(scope.row)"  size="small">查看</el-button>
          <el-button  size="small" @click="willRefund(scope.row)">退款</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 20px">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageParams.currentPage"
        :page-size="pageParams.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="pageParams.total"
      >
      </el-pagination>
    </div>
    <el-dialog title="异常支付退款" :visible.sync="dialogVisible" width="70%">
      <div>
        <h5 style="margin-bottom: 20px">支付记录</h5>
        <el-table :data="orderList" style="width: 100%">
          <el-table-column prop="parkName" :label="$t('searchModule.park_name')"> </el-table-column>
          <el-table-column prop="plateNumber" :label="$t('searchModule.plate_number')"> </el-table-column>
          <el-table-column prop="entryTime" :label="$t('searchModule.Entry_time')"> </el-table-column>
          <el-table-column prop="exitTime" :label="$t('searchModule.Appearance_time')"> </el-table-column>
          <el-table-column label="应收">
            <div slot-scope="scope">
              <span>{{ scope.row.shouldPay / 100 }}</span>
            </div>
          </el-table-column>
          <el-table-column label="优惠">
            <div slot-scope="scope">
              <span>{{ scope.row.agioPay / 100 }}</span>
            </div>
          </el-table-column>
          <el-table-column label="实收">
            <div slot-scope="scope">
              <span>{{ scope.row.money / 100 }}</span>
            </div>
          </el-table-column>
          <el-table-column prop="orderStatusDesc" label="支付状态"> </el-table-column>
          <el-table-column prop="tradeNo" :label="$t('searchModule.Third_party_serial_number')"> </el-table-column>
        </el-table>
        <h5 style="margin-top: 30px; margin-bottom: 20px">优惠券</h5>
        <el-table :data="couponVo" style="width: 100%">
          <el-table-column prop="couponName" :label="$t('searchModule.Coupon_Name')"> </el-table-column>
          <el-table-column prop="useStatusDesc" label="使用状态"> </el-table-column>
          <el-table-column prop="orderId" label="使用该券的停车支付订单ID"> </el-table-column>
        </el-table>
        <el-row :gutter="10" style="margin-top: 20px">
          <el-col :span="3">
            <span>备注：</span>
          </el-col>
          <el-col :span="21">
            <el-input
              class="el_pay_textarea"
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="remark"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            remark = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="refund">退 款</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { exportExcelNew, dateFormat } from "@/common/js/public";
export default {
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime());
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
    return {
      dialogVisible: false,
      tableData: [],
      searchDate: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      payList: [],
      payWayList: [],
      textarea: "",
      formInline: {
        type: "2",
        channelType: "",
        payType: "",
        orderNo: "",
        tradeNo: "",
        nativeTradeNo: "",
        startDate: dateFormat(start, "yyyy-MM-dd"),
        endDate: dateFormat(end, "yyyy-MM-dd"),
      },
      remark: "",
      tradeNo: "",
      repaymentId: "",
      payOrderId: "",
      paymentExceptionId: "",
      couponVo: [],
      orderList: [],
      pageParams: {
        currentPage: 1,
        pageSize: 10,
        total: 1,
      },
    };
  },
  methods: {
    getData() {},
    handleCurrentChange(page) {
      this.pageParams.currentPage = page;
      this.onSubmit();
    },
    handleCommand(command, row) {
      if (command === "check") {
        this.handleClick(row);
      } else if (command === "refund") {
        this.willRefund(row);
      }
    },
    clearData() {
      const start = new Date();
      const end = new Date(new Date().getTime());
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
      this.formInline.channelType = "";
      this.formInline.payType = "";
      this.formInline.orderNo = "";
      this.formInline.tradeNo = "";
      this.searchDate = [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")];
      // this.onSubmit()
    },
    onSubmit(page) {
      if (page) {
        this.pageParams.currentPage = page;
      }
      this.formInline.page = this.pageParams.currentPage;
      this.formInline.pageSize = this.pageParams.pageSize;
      this.formInline.startDate = this.searchDate[0];
      this.formInline.endDate = this.searchDate[1];
      this.$axios
        .get("/acb/2.0/repeatPayOrder/list", {
          data: this.formInline,
        })
        .then((res) => {
          // console.log(res);
          // 支付方式1:余额支付,2:现金支付,3:微信H5支付,4:微信app支付,5:支付宝app支付,6:微信扫码支付,7:支付宝扫码支付,8:微信二维码支付,9:支付宝二维码支付,10:微信静态二维码支付,11:支付宝态二维码支付
          /* res.value.list.forEach(item => {
            switch (item.payType) {
              case 1:
                item.payTypeName = '余额支付'
                break;
              case 2:
                item.payTypeName = '现金支付'
                break;
              case 3:
                item.payTypeName = '微信H5支付'
                break;
              case 4:
                item.payTypeName = '微信app支付'
                break;
              case 5:
                item.payTypeName = '支付宝app支付'
                break;
              case 6:
                item.payTypeName = '微信扫码支付'
                break;
              case 7:
                item.payTypeName = '支付宝扫码支付'
                break;
              case 8:
                item.payTypeName = '微信二维码支付'
                break;
              case 9:
                item.payTypeName = '支付宝二维码支付'
                break;
              case 10:
                item.payTypeName = '微信静态二维码支付'
                break;
              default:
                item.payTypeName = '支付宝态二维码支付'
                break;
            }
          }); */
          this.tableData = res.value.list;
          this.pageParams.currentPage = res.value.pageNum;
          this.pageParams.pageSize = parseInt(res.value.pageSize);
          this.pageParams.total = parseInt(res.value.total);
          // eslint-disable-next-line no-bitwise
          // this.total = res.value.total * 1 | 0
        })
        .catch((_) => {
          this.tableData = [];
          this.total = 0;
        });
    },
    // 支付渠道
    getPayType(data) {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          this.payList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
    // 支付方式
    getPayWay(data) {
      this.$axios
        .get("/acb/2.0/payment/payTypeDictList")
        .then((res) => {
          this.payWayList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
    handleClick(row) {
      this.couponVo = [];
      // paymentId
      // console.log('-->', row);
      this.tradeNo = row.tradeNo;
      this.repaymentId = row.rePaymentId;
      this.payOrderId = row.payOrderId;
      this.paymentExceptionId = row.id;
      this.$axios
        .get("/acb/2.0/repeatPayOrder/repeatPayDetail", {
          data: {
            id: row.id,
            type: 2,
          },
        })
        .then((res) => {
          // console.log('repeatPayDetail-->', res);
          // this.tableData = res.value.list
          // this.couponVo = res.value.couponVo
          this.couponVo.push(res.value.couponVo);
          this.orderList = res.value.orderList;
          this.dialogVisible = true;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    exportData() {
      exportExcelNew("/acb/2.0/repeatPayOrder/download", this.formInline);
    },
    willRefund(row) {
      this.tradeNo = row.tradeNo;
      this.repaymentId = row.rePaymentId;
      this.payOrderId = row.payOrderId;
      this.paymentExceptionId = row.id;
      // this.$alert('是否进行退款', this.$t('pop_up.Tips'), {
      //   confirmButtonText: this.$t('pop_up.Determine'),
      //   callback: action => {
      //     this.refund()
      //   }
      // });
      this.$confirm("是否进行退款?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.refund();
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消退款'
          // });
        });
    },
    // 退款
    refund() {
      // console.log(this.repaymentId);
      this.$axios
        .post("/acb/2.0/repeatPayOrder/submitRefund", {
          data: {
            refundType: 7,
            tradeNo: this.tradeNo,
            remark: this.remark,
            repaymentId: this.repaymentId,
            payOrderId: this.payOrderId,
            paymentExceptionId: this.paymentExceptionId,
          },
        })
        .then((res) => {
          // this.payWayList = res.value
          this.$message("退款成功！");
          this.dialogVisible = false;
          this.onSubmit();
        })
        .catch(() => {
          this.payList = [];
        });
      // console.log(row);
    },
  },
  mounted() {
    if (this.$route.params.orderNo) {
      console.log("this.$route.params.orderNo", this.$route.params.orderNo);
      this.formInline.orderNo = this.$route.params.orderNo;
    }
    this.getPayType();
    this.getPayWay();
    this.onSubmit();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el_pay_textarea{
  >>> textarea{
    resize:none
  }
}
.el-tabs--card>.el-tabs__header
  margin-bottom 0
</style>
